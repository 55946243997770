import logo from './logo.svg';
import {BrowserRouter, Route, Routes, useParams} from "react-router-dom";
import Home from "./components/Home";
import EventPayment from "./components/EventPayment";
import {createTheme, ThemeProvider} from "@mui/material";
import Expenses from "./components/Expenses";
import ProofOfTicket from "./components/ProofOfTicket";
import RetiroSalaEmergente from "./components/RetiroSalaEmergente";
import Login from "./components/Login";
import {login} from "./redux/userSlice";
import {useEffect} from "react";
import store from "./redux/configureStore";
import {Provider} from "react-redux";
import ExpensesDetails from "./components/ExpensesDetails";
import BalanceSheet from "./components/BalanceSheet";
import ProfitAndLoss from "./components/ProfitAndLoss";
import EventList from "./components/EventList";
import EventDetails from "./components/EventDetails";
import ModifyTicket from "./components/ModifyTicket";
import ProducerAuth from "./components/ProducerAuth";

function App() {

    const lightTheme = createTheme({
        palette: {
            mode: 'light',
        }
    });

    const getToken = window.localStorage.getItem('token');

    useEffect(() => {
        if (getToken) {
            store.dispatch(login({
                token: getToken,
            }));
        }
    }, [getToken]);

    const OrderId = () => {
        let { id } = useParams();
        return <ProofOfTicket id={id} />
    }

    const EventId = () => {
        let { id } = useParams();
        return <EventDetails id={id} />
    }


  return (
      <Provider store={store}>
          <div className="App">
            <ThemeProvider theme={lightTheme}>
              <BrowserRouter>
                  <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path={"/event-payment"} element={<EventPayment />} />
                      <Route path={"/retiro-sala-emergente"} element={<RetiroSalaEmergente />} />
                      <Route path={"/transactions/new"} element={<Expenses />} />
                      <Route exact path={"/transactions"} element={<ExpensesDetails />} />
                      <Route path={"/financial-statement"} element={<BalanceSheet />} />
                      <Route path={"/profit-and-loss-statement"} element={<ProfitAndLoss />} />
                      <Route path={"/event-list"} element={<EventList />} />
                      <Route path={"/event/:id"} element={<EventId />} />
                      <Route path={"/login"} element={<Login />} />
                      <Route path={"/order/:id"} element={<OrderId />} />
                      <Route path={"/modify-ticket"} element={<ModifyTicket />} />

                      <Route path={"/producer-auth"} element={<ProducerAuth />} />
                  </Routes>
              </BrowserRouter>
            </ThemeProvider>
        </div>
      </Provider>
  );
}

export default App;
