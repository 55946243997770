import React, {useCallback, useEffect, useState} from 'react';
import {Container} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {number_format} from "./functions/numberFormat";
import DateFilter from "./DateFilter";

const ProfitAndLoss = () => {

    const user = useSelector(selectUser);

    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });

    const fetchData = async () => {
        const request = await fetch(`https://internal-api.flashpass.com.ar/profit-and-loss-statement.php?date_from=${dateRange.fromDate}&date_to=${dateRange.toDate}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });

        const response = await request.json();
        setData(response);
    }

    const handleDateRangeChange = useCallback((newDateRange) => {
        setDateRange(newDateRange);
    }, []);

    useEffect(() => {
        fetchData();
    }, [user.token, dateRange]);

    useEffect(() => {
        console.log(dateRange);
    }, [dateRange]);

    return (
        <div>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1>P&L Statement</h1>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col">
                        <div className="date-filter-wrapper" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                            <DateFilter
                                fromDate={dateRange.fromDate}
                                toDate={dateRange.toDate}
                                onDateRangeChange={handleDateRangeChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-12">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h3>Income</h3>
                            </div>
                            <div className="col">
                                <b>$ {number_format(data?.ARS?.total_income,0,',','.')} ({number_format(data?.USD?.total_income,0,',','.')} USD)</b>
                            </div>
                        </div>
                        {data?.ARS?.income?.map((item, index) => (
                            <div className="row mt-2 align-items-center" key={index}>
                                <div className="col-6">
                                    <p className="mb-0 ms-4">{item.name}</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">$ {number_format(item.amount,0,',','.')} ({number_format(data?.USD?.income[index],0,',','.')} USD)</p>
                                </div>
                            </div>
                        ))}
                        <hr />
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h3>Expenses</h3>
                            </div>
                            <div className="col">
                                <b>$ {number_format(data?.ARS?.total_expenses,0,',','.')} ({number_format(data?.USD?.total_expenses,0,',','.')} USD)</b>
                            </div>
                        </div>
                        {data?.ARS?.expenses?.map((item, index) => (
                            <div className="row mt-2 align-items-center" key={index}>
                                <div className="col-6">
                                    <p className="mb-0 ms-4">{item.name}</p>
                                </div>
                                <div className="col">
                                    <p className="mb-0">$ {number_format(item.amount, 0, ',', '.')} ({number_format(data?.USD?.expenses[index], 0, ',', '.')} USD)</p>
                                </div>
                            </div>
                        ))}
                        <hr/>
                        <div className="row mt-4 align-items-center">
                            <div className="col-6">
                                <h3>Profit</h3>
                            </div>
                            <div className="col">
                                <b>$ {number_format(data?.ARS?.total_income + data?.ARS?.total_expenses,0,',','.')} ({number_format(data?.USD?.total_income + data?.USD?.total_expenses,0,',','.')} USD)</b>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ProfitAndLoss;