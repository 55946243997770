import React, {useEffect, useState} from 'react';
import {Button, Container} from "@mui/material";
import {useNavigate} from "react-router";

const EventDetails = (props) => {

    const [event, setEvent] = useState([]);
    const token = localStorage.getItem('token');
    const [disabledButton, setDisabledButton] = useState(false);

    const navigate = useNavigate();
    const fetchData = async () => {
        const request = await fetch(`https://internal-api.flashpass.com.ar/event-approve?event_id=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            }
        });

        const response = await request.json();

        if (response.status === 'success') {
            setEvent(response.data);
        }
    }

    const approveEvent = async () => {
        try {
            setDisabledButton(true);
            const request = await fetch(`https://internal-api.flashpass.com.ar/event-approve`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'credentials': 'same-origin',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({
                    event: props.id,
                })
            });

            const response = await request.json();

            if (response.status === 'success') {
                navigate('/event-list');
                setDisabledButton(false);
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={"mb-4"}>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-3 mt-3">
                                <img src={event.portada} width="100%" height="100%"/>
                            </div>
                            <div className="col-lg-9 mt-3">
                                <h1>Titulo: {event.event_name}</h1>
                                <p>Descripción: {event.description}</p>
                                <p>Fecha inicio: {event.event_date}</p>
                                <p>Fecha fin: {event.event_date_end}</p>
                                <p>Link: <a target={"_blank"} href={`https://flashpass.com.ar/events/${event.event_url}`}>{`https://flashpass.com.ar/events/${event.event_url}`}</a></p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <h3>Tickets</h3>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Descripción</th>
                                        <th>Precio</th>
                                        <th>Stock</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {event.event_tickets && event.event_tickets.map((ticket, index) => (
                                        <tr key={index}>
                                            <td>{ticket.event_ticket_name}</td>
                                            <td>{ticket.event_ticket_description}</td>
                                            <td>$ {ticket.event_ticket_value}</td>
                                            <td>{ticket.event_ticket_limit}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <Button variant="contained" disabled={disabledButton} className={"me-3"} color="success" onClick={approveEvent}>Aprobar
                                    Evento</Button>

                                <Button variant="contained" color="error">Rechazar
                                    Evento</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default EventDetails;