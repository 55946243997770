import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {useNavigate} from "react-router";

const Home = () => {

    const token = window.localStorage.getItem('token');

    const navigate = useNavigate();
    const [showMenu, setShowMenu] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    const fetchData = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/user-permissions', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            }
        });

        const data = await request.json();

        if (data.status === "success") {
            setOptions(data.data);
        }
    }

    const validateToken = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/check-jwt.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            }
        });


        try {
            const response = await request;

            let json = null;

            try {
                json = await response.json();
            } catch (error) {

            }

            if (json?.status === 'error' || response.status === 401) {
                window.localStorage.removeItem('token');
                navigate('/login');
            } else {
                setShowMenu(true);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }

        validateToken();

        fetchData();
    }, [token]);

    return (
        <div>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1>Inicio</h1>
                    </div>
                </div>

                {showMenu && (
                <div className="row">
                    <div className="col-12">
                        <ul>
                            {options.map((option, index) => (
                                <li key={index}><a href={option.name}>{option.description}</a></li>
                            ))}
                        </ul>
                    </div>
                </div>
                )}

            </Container>
        </div>
    );
};

export default Home;