import React, {useEffect, useState} from 'react';
import {Button} from "@mui/material";

const EventList = () => {

    const [events, setEvents] = useState([]);
    const token = localStorage.getItem('token');
    const fetchData = async () => {
        const request = await fetch(`https://internal-api.flashpass.com.ar/event-approve`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + token,
            }
        });

        const response = await request.json();

        if (response.status === 'success') {
            setEvents(response.data);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div>
            <div className="jumbotron">
                <div className="row justify-content-center">
                    <h1>Eventos Futuros</h1>
                </div>
            </div>

            <div className="container mb-4">
                <div className="col-12 table-responsive table-sm mt-3" style={{maxHeight: "600px"}}>
                    <table className="table" id="myTable">
                        <thead>
                        <tr>
                            <th>Portada</th>
                            <th>Evento</th>
                            <th>Acciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {events.map((event, index) => (
                            <tr key={index}>
                                <td className="align-middle"><img height="100px" width="100px"
                                                                  src={event.portadaa}/></td>
                                <td className="align-middle">{event.event_name}</td>
                                <td className="align-middle">
                                    <Button variant="contained" color="primary" href={`/event/${event.event_id}`}>Ver
                                        Evento</Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EventList;