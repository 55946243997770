import React, {useEffect, useState} from 'react';
import {Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {number_format} from "./functions/numberFormat";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import './styles/ProofOfTicket.css';

const ProofOfTicket = (props) => {

    const user = useSelector(selectUser);

    const [data, setData] = useState([]);
    const fetchData = async () => {
        const request = await fetch(`https://internal-api.flashpass.com.ar/proof-of-purchase.php?id=${props.id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            },
        });

        const response = await request.json();
        setData(response.data);
    }

    useEffect(() => {
        fetchData();
    }, [user.token, props.id]);

    return (
        <div>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1>Detalle de órden</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <small>El siguiente documento certifica la obtención de los eTickets y estado de los mismos para el ingreso al evento detallado en el mismo, así como la fecha de adquisición y de uso.
                            FlashPass certifica que el eTicket fue emitido, por lo que no se obtiene en si una disposición al retornos de fondos al comprador identificado en este documento.</small>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <h2>Datos del comprador</h2>
                        <p className="mb-0">Nombre del comprador: {data.buyer_name}</p>
                        <p className="mb-0">DNI del comprador: {data.buyer_dni}</p>
                        <p className="mb-0">Email del comprador: {data.buyer_email}</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <h2>Detalle de compra</h2>
                        <p className="mb-0">ID de Compra: {props.id}</p>
                        <p className="mb-0">Payment ID: {data.payment_id}</p>
                        <p className="mb-0">Monto: {number_format(data.monto,0, ',', '.')}</p>
                        <p className="mb-0">Cargos por servicio: {number_format(data.comision,0, ',', '.')}</p>
                        <p className="mb-0">Total: {number_format(data.total,0, ',', '.')}</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <h2>Detalle de evento</h2>
                        <p className="mb-0">Nombre del evento: {data.event_name}</p>
                        <p className="mb-0">Fecha del evento: {data.event_date}</p>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <h2 className="mb-3">Detalle de asistentes</h2>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID de Ticket</TableCell>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>DNI</TableCell>
                                        <TableCell>Precio</TableCell>
                                        <TableCell>Estado ticket</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.tickets?.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{row.ticket_id}</TableCell>
                                            <TableCell>{row.ticket_name}</TableCell>
                                            <TableCell>{row.ticket_dni}</TableCell>
                                            <TableCell>{number_format(row.ticket_value,0,',','.')}</TableCell>
                                            <TableCell>{row.ticket_status}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ProofOfTicket;