import React, {useEffect, useState} from 'react';
import {Container} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUser} from "../redux/userSlice";
import {number_format} from "./functions/numberFormat";

const BalanceSheet = () => {

    const user = useSelector(selectUser);

    const [expenses, setExpenses] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(0);

    const fetchData = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/balance-sheet.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });

        const response = await request.json();
        setExpenses(response.data);
        setExchangeRate(response.exchangeRate);
    }

    useEffect(() => {
        fetchData();
    }, [user.token]);

    return (
        <div>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1>Balance Sheet</h1>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-6">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h3>Activo</h3>
                            </div>
                            <div className="col">
                                <b>$ {number_format(expenses.filter(expense => expense.type === 'Asset').reduce((a, b) => a + parseFloat(b.amount), 0),0,',','.')} ({number_format(expenses.filter(expense => expense.type === 'Asset').reduce((a, b) => a + parseFloat(b.amount), 0) / exchangeRate,0,',','.')} USD)</b>
                            </div>
                        </div>
                        <div className="row mb-3 align-items-center">
                                {expenses.filter(expense => expense.type === 'Asset').map((expense, index) => (
                                    <>
                                        <div key={index} className="col-6">
                                            <p className="mb-2">{expense.name}</p>
                                        </div>
                                        <div key={index} className="col">
                                            <p className="mb-2">$ {number_format(expense.amount, 0, ',', '.')} ({number_format(expense.amount / exchangeRate, 0, ',', '.')} USD)</p>
                                        </div>
                                    </>
                                ))}
                        </div>
                        <hr className={"d-block d-lg-none"} />
                    </div>

                    <div className="col-lg-6">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <h3>Pasivo</h3>
                            </div>
                            <div className="col">
                                <b>$ {number_format(expenses.filter(expense => expense.type === 'Liability').reduce((a, b) => a + parseFloat(b.amount), 0),0,',','.')} ({number_format(expenses.filter(expense => expense.type === 'Liability').reduce((a, b) => a + parseFloat(b.amount), 0) / exchangeRate,0,',','.')} USD)</b>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            {expenses.filter(expense => expense.type === 'Liability').map((expense, index) => (
                                <>
                                    <div key={index} className="col-6">
                                        <p className="mb-2">{expense.name}</p>
                                    </div>
                                    <div key={index} className="col">
                                        <p className="mb-2">$ {number_format(expense.amount, 0, ',', '.')} ({number_format(expense.amount / exchangeRate, 0, ',', '.')} USD)</p>
                                    </div>
                                </>
                            ))}
                        </div>
                        <hr/>
                        <div className="row mt-4 align-items-center">
                            <div className="col-6">
                                <h3>Patrimonio Neto</h3>
                            </div>
                            <div className="col">
                                <b>$ {number_format(expenses.filter(expense => expense.type === 'Equity').reduce((a, b) => a + parseFloat(b.amount), 0),0,',','.')} ({number_format(expenses.filter(expense => expense.type === 'Equity').reduce((a, b) => a + parseFloat(b.amount), 0) / exchangeRate,0,',','.')} USD)</b>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            {expenses.filter(expense => expense.type === 'Equity').map((expense, index) => (
                                <>
                                    <div key={index} className="col-6">
                                        <p className="mb-2">{expense.name}</p>
                                    </div>
                                    <div key={index} className="col">
                                        <p className="mb-2">$ {number_format(expense.amount, 0, ',', '.')} ({number_format(expense.amount / exchangeRate, 0, ',', '.')} USD)</p>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default BalanceSheet;