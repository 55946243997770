import React, {useEffect, useState} from 'react';
import {selectUser} from "../redux/userSlice";
import {useSelector} from "react-redux";
import {Container} from "@mui/material";
import {number_format} from "./functions/numberFormat";
import {BiDownload} from "react-icons/bi";
const ExpensesDetails = () => {

    const user = useSelector(selectUser);

    const [expenses, setExpenses] = useState([]);

    const fetchData = async () => {
        const request = await fetch('https://internal-api.flashpass.com.ar/expenses.php', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            }
        });

        const response = await request.json();
        setExpenses(response.data);
    }

    useEffect(() => {
        fetchData();
    }, [user.token]);

    return (
        <div>
            <Container>
                <div className="row">
                    <div className="col-12">
                        <h1>Transactions</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-striped table-hover">
                            <thead>
                            <tr>
                                <th scope="col">Fecha</th>
                                <th scope="col">Descripción</th>
                                <th scope="col">Categoría</th>
                                <th scope="col">Monto</th>
                                <th scope="col">Descargar</th>
                            </tr>
                            </thead>
                            <tbody>
                            {expenses.map((expense, index) => (
                                <tr key={index}>
                                    <td>{expense.date}</td>
                                    <td>{expense.description}</td>
                                    <td>{expense.category}</td>
                                    <td>{number_format(expense.amount, 2, ',', '.')}</td>
                                    <td>
                                        <a href={expense.downloadUrl} target="_blank" rel="noreferrer">
                                            <BiDownload />
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ExpensesDetails;