import React, { useEffect, useState } from 'react';
import {TextField, Autocomplete, Button, Snackbar, Alert} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';

const ModifyTicket = () => {
    const [search, setSearch] = useState('');
    const [ticket, setTicket] = useState(null);
    const [ticketsSearched, setTicketsSearched] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const user = useSelector(selectUser);

    const fetchSearch = async () => {
        const request = await fetch(`https://internal-api.flashpass.com.ar/ticket.php?search=${search}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            },
        });

        const response = await request.json();

        if (response.status === 'success') {
            setTicketsSearched(response.data);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonDisabled(true);

        if (ticket) {
            const request = await fetch(`https://internal-api.flashpass.com.ar/ticket.php`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token,
                },
                body: JSON.stringify(ticket),
            });

            const response = await request.json();

            if (response.status === 'success') {
                setSuccessMessage(response.message);
                setOpenS(true);
                setButtonDisabled(false);
            } else {
                setErrorMessage(response.message);
                setOpenE(true);
            }
        }
    };

    useEffect(() => {

        if (search === '') {
            setTicketsSearched([]);
            return;
        }

        // Create a timeout to delay the search
        const timeoutId = setTimeout(() => {
            fetchSearch();
        }, 300); // 500ms delay

        // Cleanup function to cancel the timeout if a new letter is typed
        return () => clearTimeout(timeoutId);
    }, [search]);


    const handleInputChange = (key, value) => {
        setTicket({
            ...ticket,
            [key]: value,
        });
    };

    return (
        <div className="container">
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className="row">
                <div className="col-12">
                    <h1>Modificar Ticket</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Autocomplete
                        options={ticketsSearched}
                        getOptionLabel={(option) => `${option.ticket_name}-${option.ticket_id}`}
                        filterOptions={(options, params) => {
                            return options; // This will return all options without filtering
                        }}
                        onChange={(event, newValue) => {
                            setTicket(newValue);
                        }}
                        inputValue={search}
                        onInputChange={(event, newInputValue) => {
                            setSearch(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Search for a ticket" variant="outlined" fullWidth />
                        )}
                    />
                </div>
            </div>
            {ticket && (
                <form onSubmit={handleSubmit}>
                    <div className="row mt-3">
                        <div className="col-12">
                            <h3>Información del Ticket</h3>
                            {ticket?.menta_sold === 1 && (
                                <Alert
                                    severity="warning"
                                    className="mb-3"
                                >
                                    Este ticket fue <b>vendido en Menta</b>, no modifique el nombre, DNI o email.
                                </Alert>
                            )}
                            <TextField
                                label="Ticket ID"
                                value={ticket.ticket_id}
                                onChange={(e) => handleInputChange('ticket_id', e.target.value)}
                                variant="outlined"
                                fullWidth
                                disabled
                                className="mt-3"
                            />
                            <TextField
                                label="Tipo de Ticket"
                                value={ticket.event_ticket_name}
                                variant="outlined"
                                fullWidth
                                disabled
                                className="mt-3"
                            />
                            <TextField
                                label="Nombre"
                                value={ticket.ticket_name}
                                onChange={(e) => handleInputChange('ticket_name', e.target.value)}
                                variant="outlined"
                                fullWidth
                                className="mt-3"
                            />
                            <TextField
                                label="DNI"
                                value={ticket.ticket_dni}
                                onChange={(e) => handleInputChange('ticket_dni', e.target.value)}
                                variant="outlined"
                                fullWidth
                                className="mt-3"
                            />
                            <TextField
                                label="Email"
                                value={ticket.ticket_email}
                                onChange={(e) => handleInputChange('ticket_email', e.target.value)}
                                variant="outlined"
                                fullWidth
                                className="mt-3"
                            />
                            <h3>Otra Información</h3>
                            <TextField
                                label="Event Date GMT+0"
                                value={ticket.event_date}
                                variant="outlined"
                                fullWidth
                                disabled
                                className="mt-3"
                            />
                            <TextField
                                label="Event Date End GMT+0"
                                value={ticket.event_date_end}
                                variant="outlined"
                                fullWidth
                                disabled
                                className="mt-3"
                            />
                            <TextField
                                label="Event ID"
                                value={ticket.event_id}
                                variant="outlined"
                                fullWidth
                                disabled
                                className="mt-3"
                            />
                            <TextField
                                label="Event Name"
                                value={ticket.event_name}
                                variant="outlined"
                                fullWidth
                                disabled
                                className="mt-3"
                            />
                        </div>
                    </div>
                    <div className="row mt-3 mb-4">
                        <div className="col-12">
                            <Button variant="contained" color="primary" type={"submit"} disabled={buttonDisabled}>
                                Modificar Ticket
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

export default ModifyTicket;
