import React, { useEffect, useState } from 'react';
import {TextField, Autocomplete, Button, Snackbar, Alert} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/userSlice';

const ProducerAuth = () => {
    const [search, setSearch] = useState('');
    const [ticket, setTicket] = useState(null);
    const [ticketsSearched, setTicketsSearched] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const user = useSelector(selectUser);

    const fetchSearch = async () => {
        const request = await fetch(`https://internal-api.flashpass.com.ar/producer-auth.php?search=${search}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
                'Authorization': 'Bearer ' + user.token,
            },
        });

        const response = await request.json();

        if (response.status === 'success') {
            setTicketsSearched(response.data);
        }
    };

    const handleSubmit = async (e) => {
        setButtonDisabled(true);

        if (ticket) {
            const request = await fetch(`https://internal-api.flashpass.com.ar/producer-auth.php`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user.token,
                },
                body: JSON.stringify(ticket),
            });

            const response = await request.json();

            if (response.status === 'success') {
                setSuccessMessage(response.message);
                setOpenS(true);
                setButtonDisabled(false);

                setTimeout(() => {
                    window.location.href = `https://app.flashpass.com.ar/google_auth?redirect=&jwt=${response.data}`;
                }, 1000);
            } else {
                setErrorMessage(response.message);
                setOpenE(true);
            }
        }
    };

    useEffect(() => {
        handleSubmit();
    }, [ticket]);

    useEffect(() => {
        // Create a timeout to delay the search
        const timeoutId = setTimeout(() => {
            fetchSearch();
        }, 300); // 500ms delay

        // Cleanup function to cancel the timeout if a new letter is typed
        return () => clearTimeout(timeoutId);
    }, [search]);


    const handleInputChange = (key, value) => {
        setTicket({
            ...ticket,
            [key]: value,
        });
    };

    return (
        <div className="container">
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <div className="row">
                <div className="col-12">
                    <h1>Ingresar a productor</h1>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Autocomplete
                        options={ticketsSearched}
                        getOptionLabel={(option) => `${option.producer_name}-${option.producer_id}`}
                        filterOptions={(options, params) => {
                            return options; // This will return all options without filtering
                        }}
                        onChange={(event, newValue) => {
                            setTicket(newValue);
                        }}
                        inputValue={search}
                        onInputChange={(event, newInputValue) => {
                            setSearch(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Search for a producer" variant="outlined" fullWidth />
                        )}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProducerAuth;
