import React, {useState} from 'react';
import {Alert, Button, Container, Snackbar, TextField} from "@mui/material";

const Login = () => {

    const [values, setValues] = useState({
        username: '',
        password: '',
        login_user: true,
    });

    const [openS, setOpenS] = useState(false);
    const [openE, setOpenE] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseS = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenS(false);
    };

    const handleCloseE = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenE(false);
    };

    const submitData = async (e) => {
        e.preventDefault();
        const request = await fetch('https://internal-api.flashpass.com.ar/authentication.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'credentials': 'same-origin',
            },
            body: JSON.stringify(values),
        });

        if (request.ok) {
            const response = await request.json();
            window.localStorage.setItem('token', response.auth);
            setSuccessMessage('Login exitoso');
            setOpenS(true);
            setTimeout(() => {
                window.location.href = '/';
            }, 1000);
        } else {
            setErrorMessage('Usuario o contraseña incorrectos');
            setOpenE(true);
        }
    }

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <div>
            <Snackbar open={openS} autoHideDuration={6000} onClose={handleCloseS}>
                <Alert onClose={handleCloseS} severity="success" sx={{ width: '100%' }}>
                    {successMessage}
                </Alert>
            </Snackbar>
            <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
                <Alert onClose={handleCloseE} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Container>
                <h1>Login</h1>
                <form onSubmit={submitData}>
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField label="Usuario" value={values.username} onChange={handleChange('username')} className="mb-3" fullWidth variant="outlined" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <TextField label="Contraseña" value={values.password} onChange={handleChange('password')} type={"password"} className="mb-3" fullWidth variant="outlined" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <Button variant="contained" color="primary" type={"submit"} fullWidth>
                                Ingresar
                            </Button>
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    );
};

export default Login;